import { useState, useEffect, createElement } from 'react';
import * as SurveyJS from 'survey-react-ui';
import { Model, surveyLocalization } from "survey-core";
import surveyClasses from './surveyjs-classes.json';
import ResponseService, { SurveyConfig } from '../../services/response';
import { registeredSurveysViva, registeredSurveysTecnolite, registeredSurveysCx } from '../../surveys/index';
import { PageHeader, MainLayout } from '../../components/main.layout';
import { RouteComponentProps } from 'react-router-dom';
import { PageExtension, SupportedLanguages, typesPages } from '../../utils/common-types';
import { replaceValues } from '../../services/handlebars';
import { executeFunction } from '../../services/helpers';
import "survey-core/survey.i18n";
import { ReactElementFactory, ReactSurveyElement } from 'survey-react-ui';
import { isMobile } from 'react-device-detect';

const esLocale = surveyLocalization.locales["es"];

esLocale.requiredError =  "Por favor responde la pregunta.";
esLocale.otherRequiredError =  "Por favor agrega el valor de Otro.";
esLocale.requiredInAllRowsError =  "Selecciona una respuesta para cada una de las filas.";
esLocale.invalidEmail = "Por favor agregue un correo electrónico válido."

let registeredSurveys: any[] = [];
switch (process.env.REACT_APP_DEMO_SURVEYS) {
  case "viva":
    registeredSurveys = registeredSurveysViva;
    break;
  case "tecnolite":
    registeredSurveys = registeredSurveysTecnolite;
    break;
  case "cxmate":
    registeredSurveys = registeredSurveysCx;
    break;
}

const setCssClasses = (survey: any, options: any) => {
  switch (options.question.getType()) {
    case 'rating':
      if (options.question.rateMax === 10) {
        options.cssClasses.item = 'nps-button';
        options.cssClasses.itemText = 'nps-label';
      }
      if (options.question.rateMax === 5) {
        options.cssClasses.item = 'radio-button';
        options.cssClasses.itemText = 'radio-button-label';
      }
      break;
    
    case 'matrix':
      if (options.question.visibleRows?.length === 1) {
        options.cssClasses.root += ' one-row-matrix';
        options.cssClasses.content += ' wide';
      }
      if (options.question.columns?.length > 4) {
        options.cssClasses.root += ' full-matrix';
        options.cssClasses.content += ' wide';
      }
      break;
      
    case 'html':
      console.log(options.question)
      if (options.question.html?.includes('<h3>')) {
        options.cssClasses.mainRoot += ' top-label'
      }
      break;
  
    default:
      break;
  }
}

const processLocalizedStrings = (survey: Model, options: any) => {
  if (typeof options.value === 'object') {
    options.value = options.value[survey.locale]
  }
}

class PercentageProgressBar extends ReactSurveyElement {
  render() {
      return (
          <div className="sv-progressbar-percentage">
            <div className="sv-progressbar-percentage__indicator">
              <div className="sv-progressbar-percentage__value-bar" style={{ width: this.props.model.progressValue + "%" }}></div>
            </div>
            <div className="sv-progressbar-percentage__value">
              <span>{JSON.stringify(this.props.model.progressValue) + "%"}</span>
            </div>
          </div>
      );
  }
}

ReactElementFactory.Instance.registerElement("sv-progressbar-percentage", props => {
  return createElement(PercentageProgressBar, props);
});

enum SurveyFixedValueT {
  NONEVALUE = '98',
  OTHERVALUE = '99',
  NONETEXT = 'none',
  OTHERTEXT = 'other',
  COMMENT = '-Comment'
}

enum CxmFixedValueT {
  FIRSTCHOICEVALUE = '_first_choice_value',
  FISRTCHOICETEXT = '_first_choice_text',
  OTHER = '_other',
  SELECTEDREASONSNUMBER = '_selected_reasons_number'
}

export function Survey (props: RouteComponentProps<{ id: string }>) {
  const id: string = props?.match?.params?.id;
  const queryParameters = new URLSearchParams(window.location.search)
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});
  const [surveyConfig, setSurveyConfig] = useState<SurveyConfig>({});
  const [surveyModel, setSurveyModel] = useState({});
  const [selectedLocale, setSelectedLocale] = useState(SupportedLanguages.es);
  const [skipButton, setSkipButton] = useState(false);
  
  function getPrefilledFields (base: Record<string, any> = {}) {
    const search: string = props?.history?.location?.search;
    let prefilled: Record<string, any> = Object.assign(base);
    if (search.length > 0) {
      const params = search.split(/[?&]/);
      params.forEach(p => {
        if (!p.length) return;
        const [key, value] = p.split('=');
        prefilled[key] = decodeURIComponent(value);
      })
    }

    return prefilled;
  }

  async function loadSurveyConfig (id: string) {
    setLoading(true);
    let surveyConfig = undefined;
    const prefilled = getPrefilledFields();
    const parameters =  Object.fromEntries(queryParameters);
    if (isMobile) prefilled['DETECTED_DEVICE'] = '2'
    else prefilled['DETECTED_DEVICE'] = '1'
    try {
      if (String(props?.match?.url).includes('test')) {
        const testSurveyConfig = registeredSurveys.find(s => s.id === id);
        surveyConfig = testSurveyConfig?.config;
      }
      else if (String(props?.match?.url).includes('survey')) {
        const remoteSurveyConfig: SurveyConfig = await ResponseService.loadSurveyAnonymousDef(id, parameters);
        surveyConfig = remoteSurveyConfig;
      } else {
        const remoteSurveyConfig: SurveyConfig = await ResponseService.loadSurveyDef(id);
        surveyConfig = remoteSurveyConfig;
      }
      if (surveyConfig) {
        setSelectedLocale(surveyConfig.preferredLanguage || SupportedLanguages.es);
        setSurveyConfig({ ...surveyConfig, data: { ...surveyConfig.data , ...prefilled } });
        const survey = new Model(surveyConfig.surveyDef)
        if (surveyConfig.surveyDef.progressBarType && !surveyConfig.surveyDef.showProgressBar)
          survey.addLayoutElement({
            id: "progressbar-percentage",
            component: "sv-progressbar-percentage",
            container: "contentTop",
            data: survey
        });
       setSurveyModel(survey);
      }
    } catch (err) {
      if (err instanceof Error)
      props?.history?.replace('/oops', {
        title: {
          es: '¡Ooops! Algo salió mal',
          default: 'Ooops! Something happened'
        },
        // @ts-ignore
        message: err.cause,
      })
    } finally {
      setLoading(false);
    }
  }

  async function saveResponse (response: any, rawResponse: any, language: SupportedLanguages) {
    if (!surveyConfig.answerId) return console.log('Response: ', response);
    try {
      return await ResponseService.saveResponse(surveyConfig.answerId, response, rawResponse, language);
    } catch (ex) {
      console.log('Ocurrió un error al guardar.', ex);
    }
  }

  async function onValidateQuestion (survey: any) {
    const rawResponse = survey?.valuesHash;
    const currentQuestions = survey.currentPage.questions;
    console.log('Survey', survey);
    console.log('Change', rawResponse);
    
    const firstChoice = currentQuestions.find((q: any) => {
      if (q.getType() === 'checkbox') return q;
      return null;
    });

    if (firstChoice && rawResponse[firstChoice.valueName]) {
      if (rawResponse[firstChoice.valueName][0] !== SurveyFixedValueT.NONETEXT) {
        let idx = 0;
        if (rawResponse[firstChoice.valueName][0] === SurveyFixedValueT.OTHERTEXT) {
          idx++;
          rawResponse[firstChoice.valueName + CxmFixedValueT.FISRTCHOICETEXT] = firstChoice.localizableStrings.otherText.renderedText;
          rawResponse[firstChoice.valueName + CxmFixedValueT.FIRSTCHOICEVALUE] = SurveyFixedValueT.OTHERVALUE;
        }
        const choice = firstChoice.choices.find((e: any) => e.value === rawResponse[firstChoice.valueName][idx]);
        if (choice) {
          rawResponse[firstChoice.valueName + CxmFixedValueT.FISRTCHOICETEXT] = choice.text;
          rawResponse[firstChoice.valueName + CxmFixedValueT.FIRSTCHOICEVALUE] = choice.value;
        }
      } else {
        rawResponse[firstChoice.valueName + CxmFixedValueT.FISRTCHOICETEXT] = firstChoice.localizableStrings.noneText.renderedText;
        rawResponse[firstChoice.valueName + CxmFixedValueT.FIRSTCHOICEVALUE] = SurveyFixedValueT.NONEVALUE;
      }
      const optionsSelected = rawResponse[firstChoice.valueName].map((value: string) => {
        if (value === SurveyFixedValueT.OTHERTEXT) {
          rawResponse[firstChoice.valueName + CxmFixedValueT.OTHER] = rawResponse[firstChoice.valueName + SurveyFixedValueT.COMMENT]
          delete rawResponse[firstChoice.valueName + SurveyFixedValueT.COMMENT];
          return SurveyFixedValueT.OTHERVALUE;
        }
        else if (value === SurveyFixedValueT.NONETEXT) return SurveyFixedValueT.NONEVALUE;
        return value;
      });
      rawResponse[firstChoice.valueName] = optionsSelected;
      rawResponse[firstChoice.valueName + CxmFixedValueT.SELECTEDREASONSNUMBER] = optionsSelected.length;
    }

    const updatedResponse = { ...response, ...rawResponse };
    setResponse(updatedResponse);
    await saveResponse(updatedResponse, rawResponse, selectedLocale);
  }

  function onCurrentPageChanged(survey: any) {
    const extensions = surveyConfig.extensions && surveyConfig.extensions;
    if (extensions?.pages){
      const namePage = survey.currentPage.name;
      const isLast = survey.isLastPage;
      extensions.pages.forEach((page: PageExtension) => {
        survey.ignoreValidation = false;
        if (page.type === typesPages.SKIPPABLE) {
          if (page.names.includes(namePage)){
            const nameDisplay =  page.customButtonNames ? page.customButtonNames[namePage].skipName[selectedLocale] : page.buttonName[selectedLocale]
            if(!skipButton) {
              survey.addNavigationItem({
                id: "survey_skip",
                title: isLast ? page.buttonNameLast[selectedLocale] : nameDisplay,
                visibleIndex: 1,
                action: () => {
                  survey.ignoreValidation = true;
                  if (isLast) survey.completeLastPage();
                  else survey.nextPage();
                }
              })
              setSkipButton(true);
            } else {
              const skipButton = survey.navigationBar.getActionById("survey_skip");
              if (skipButton) skipButton.title = nameDisplay
            }
            
            const complete = survey.navigationBar.getActionById("sv-nav-complete");
            if (complete) 
              complete.title = page.completeName[selectedLocale];
          }
          else {
            const skip = survey.navigationBar.getActionById("survey_skip");
            if (skip) skip.visible = false;
            const complete = survey.navigationBar.getActionById("sv-nav-complete");
            if (skip  && complete) 
            complete.title = page.defaultCompleteName[selectedLocale];
          }
        }
      });
    }
  }

  async function toThankyou (survey: any) {
    await onValidateQuestion(survey);
    const data = survey?.valuesHash
    const rule = surveyConfig.ruleCompleteMessage ? surveyConfig.ruleCompleteMessage : '';
    const result = executeFunction(data, rule);
    if (result?.message) {
      const message = replaceValues(result.message, data)
      if (message && surveyConfig.complete) surveyConfig.complete.message = message;
    }
    if (result?.title) {
      const title = replaceValues(result.title, data)
      if (title && surveyConfig.complete) surveyConfig.complete.title = title;
    }
    return props?.history?.replace('/thank-you', {
      ...surveyConfig.complete,
      preferredLanguage: selectedLocale
    })
  }

  useEffect(() => {
    loadSurveyConfig(id);
  }, []);

  if (loading || !surveyConfig.surveyDef || !surveyModel) return null;

  return (
    <>
      <PageHeader>
        <select
          onChange={ev => setSelectedLocale(ev.target.value as SupportedLanguages)}
          className="lang-selector"
          defaultValue={surveyConfig.preferredLanguage}
         >
          <option value="es">Español</option>
          <option value="default">English</option>
        </select>
      </PageHeader>
      <MainLayout>
        <SurveyJS.Survey
          locale={selectedLocale}
          model={surveyModel}
          data={surveyConfig.data}
          css={surveyClasses}
          onUpdateQuestionCssClasses={setCssClasses}
          onProcessTextValue={processLocalizedStrings}
          onComplete={toThankyou}
          onPartialSend={onValidateQuestion}
          onCurrentPageChanged={onCurrentPageChanged}
        />
      </MainLayout>
      <footer>
        <a
          href={surveyConfig.privacyPolicy?.[selectedLocale]}
          className="privacy-policy-link"
          target="_blank"
          rel="noreferrer"
        >
        </a>
    </footer>
    </>
  )
}